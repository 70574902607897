<template>
  <div class="lg:flex">
    <div class="lg:w-6/12 text-7xl px-2">
      <div class="w-full text-center sticky top-20">
        <Gallery :itemId="props.selectedMarker?.id" :toggleButton="toggleButton" :fullScreenMode="props.fullScreenMode" />
      </div>
    </div>
    <div class="lg:w-6/12 h-full px-24">
      <div class="z-1 pb-10">
        <h2 class="font-heading text-bold text-h1 text-sepiaBlack dark:text-redDamask py-6">{{ postArray.title }}</h2>
        <p class="font-body text-paragraph whitespace-pre-wrap text-sepiaBlack dark:text-white">{{ postArray.text }}</p>
        <h5 class="font-heading text-h5 text-redDamask font-extrabold py-6">{{ $t('entity.description') }}</h5>
        <p v-html="postArray.description" class="font-body text-paragraph whitespace-pre-wrap text-sepiaBlack dark:text-white mx-auto description"></p>
        <div v-show="postArray.story">
          <h5 class="font-heading text-h5 text-redDamask font-extrabold py-6">{{ $t('entity.story') }}</h5>
          <p v-html="postArray.story" class="font-body text-paragraph whitespace-pre-wrap text-sepiaBlack dark:text-white mx-auto story"></p>
        </div>
      </div>
    </div>
  </div>
  <a v-if="fullScreenMode ? false : showButton" @click="props.changeView" class="flex gap-2 bg-redDamask border-transparent rounded-full max-w-max cursor-pointer pr-1 pl-3 py-1 absolute top-28 right-10">
    <span class="inline-block font-body text-bodyL dark:text-halfColonialWhite text-sepiaBlack my-auto">{{ $t('entity.close') }}</span>
    <BaseIcon name="closeWhite" outlineColor="white" size="w-8 h-8" />
  </a>
</template>

<script setup>
  import Gallery from '@/components/Gallery.vue';
  import BaseIcon from '@/components/BaseIcon.vue';

  import { computed, ref } from "@vue/reactivity";

  const props = defineProps({
    selectedMarker: Object,
    changeView: Function,
    fullScreenMode: Boolean,
  });

  const showButton = ref(true);

  const postArray = computed(() => {
    return {
      title: props.selectedMarker?.title,
      text: props.selectedMarker?.sport_content,
      src: props.selectedMarker?.img,
      description: props.selectedMarker?.content || props.selectedMarker?.description,
      story: props.selectedMarker?.story,
		};
  });

  const toggleButton = () => {
    showButton.value = !showButton.value;
  }
</script>