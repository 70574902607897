<template>
  <div class="h-screen w-screen flex flex-col">
    <Header />
    <div v-if="selectedInstrument != null" class="flex-1 w-full px-10 pt-5">
      <DetailsPage :changeView="changeView" :selectedMarker="selectedInstrument" />
    </div>
    <ul v-else class="grid grid-cols-4 justify-items-center mt-12">
      <li v-for="(post, index) in posts" :key="post.id">
        <EntityList tv :content="post" :itemIndex="index" :setSelectedEntity="setSelectedInstrument" />
      </li>
    </ul>
  </div>
</template>

<script setup>
  import axios from "axios";
  import { onBeforeMount, ref, watch } from "vue";

  import Header from "@/components/Header.vue";
  import EntityList from "@/components/tablet/EntityList.vue";
  import DetailsPage from "@/components/map/DetailsPage.vue";

  import { languageStore } from '../../store/store';

  const baseURL = process.env.VUE_APP_AXIOS_URL;

  const postsData = {
    per_page: -1, // get all entities
    page: 1,
    lang: languageStore.language,
    search: '',
    category: 'instruments',
  };

  const posts = ref([]);
  const selectedInstrument = ref(null);

  const getPosts = () => {
    // postsData.category = $route.query.category;
    axios.get(baseURL + `/wp-json/wp/v2/tvs`, {params: postsData}, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then(response => {
      posts.value = response.data;
    }).catch((error) => {
      console.log(error);
    });
  };

  const setSelectedInstrument = (selectedIndex) => {
    axios.get(baseURL + `/wp-json/wp/v2/tv/${posts.value[selectedIndex].id}`, {params: postsData}, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then(response => {
      selectedInstrument.value = response.data;
    }).catch((error) => {
      console.log(error);
    });
  }

  const changeView = () => {
    selectedInstrument.value = null;
  }

  watch(languageStore, () => {
    postsData.lang = languageStore.language;
    getPosts();

    if (selectedInstrument.value != null) {
      axios.get(baseURL + `/wp-json/wp/v2/tv/${selectedInstrument.value.transtations[languageStore.language]}`, {params: postsData}, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then(response => {
        selectedInstrument.value = response.data;
      }).catch((error) => {
        console.log(error);
      });
    }
  });

  onBeforeMount(() => {
    getPosts();
  });
</script>