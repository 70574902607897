<template>
  <div class="tv-card-w min-w-350 h-card overflow-ellipsis overflow-hidden py-6 hover:bg-redDamask active:bg-redDamask hover:bg-opacity-10 active:bg-opacity-10 rounded-lg m-8 relative">
    <div @click="setSelectedEntity(itemIndex)" class="cursor-pointer relative">
      <div class="h-68 w-68 mx-auto rounded relative overflow-hidden p-1">
        <img v-if="!content.scratch_game" :src="postArray.src" :alt="postArray.alt" class="mx-auto h-full overflow-hidden max-w-max	absolute left-1/2 top-0 transform -translate-x-1/2">
      </div>
      
      <div class="px-4 py-3 text-center">
        <div v-if="size == 'medium'">
          <h4 class="font-heading text-h4 text-sepiaBlack dark:text-halfColonialWhite overflow-ellipsis overflow-hidden max-h-20">{{ postArray.title }}</h4>
        </div>
        <div v-else>
          <h5 class="font-heading text-h5 text-sepiaBlack dark:text-halfColonialWhite overflow-ellipsis overflow-hidden max-h-20">{{ postArray.title }}</h5>
        </div>
        <p class="font-body text-bodyL text-sepiaBlack dark:text-halfColonialWhite opacity-60 pt-4 overflow-ellipsis overflow-hidden max-h-12">{{ postArray.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';

export default {
  name: 'EntityList',
  components: {
    Button
  },
  props: {
    size: {
      type: String,
      require: false,
      default: 'medium',
    },
    content: {
      type: Object,
      require: true,
      default: () => {},
    },
    tv: {
      type: Boolean,
      require: false,
      default: false,
    },
    itemIndex: {
      type: Number,
      require: false,
    },
    setSelectedEntity: {
      type: Function,
      require: false,
      default: () => {},
    },
  },
  computed: {
    postArray() {
      return {
        title: this.content?.title,
        text: this.content?.sport_content,
        src: this.content?.img,
        alt: this.content?.alt,
        id: this.content?.id,
      }
    },
    src() {
      //TODO change home incon to scrach game icon
      return require("../../assets/scratchDescription.png");
    },
  },
  methods: {
    newIndex: function(value) {
      if(value >= 10) {
        return value + 1;
      } 
      return `0${value+1}`
    }
  }
}
</script>
